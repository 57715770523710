import { Table, TableActions, TableBody, TableHead, TableRow, Td, Th } from '@happyfoxinc/react-ui'
import { Fragment, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useTable } from 'react-table'

import styles from './WorkspaceTable.module.css'

import PageLoader from 'Components/PageLoader'
import api from 'Services/api'

import CreateWorkspace from '../CreateWorkspace'
import WorkspaceModal from '../WorkspaceModal'
import WorkspaceOptions from './WorkspaceOptions'

const WorkspaceTable = () => {
  const [showEditModal, setShowEditModal] = useState(false)
  const [workspaceToEdit, setWorkspaceToEdit] = useState(null)

  const { data = {}, isLoading } = api.useGetWorkspacesQuery()
  const [updateWorkspace, updateWorkspaceResult] = api.useUpdateWorkspaceMutation()

  const { results: workspaces = [] } = data

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: (row) => row,
        Cell: ({ value }) => (
          <div className={styles.WorkspaceNameCell}>
            {value.name}
            {value.settings && value.settings.default ? <div className={styles.DefaultLabel}>Default</div> : null}
          </div>
        )
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value }) => {
          const MAX_LENGTH = 120
          return value && value.length > MAX_LENGTH ? `${value.substring(0, MAX_LENGTH)}...` : value || '~'
        }
      }
      // {
      //   Header: 'Connected Apps',
      //   accessor: 'connected_apps'
      // },
      // {
      //   Header: 'Connected Sources',
      //   accessor: 'connected_sources'
      // }
    ]
  }, [])

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data: workspaces
  })

  const handleRowClick = (workspace) => () => {
    setWorkspaceToEdit(workspace)
    setShowEditModal(true)
  }

  const handleEditWorkspace = async (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      visibility: data.visibility,
      example_queries: data.example_queries
    }

    if (data.visibleToGroups) {
      payload.mapped_user_groups = data.visibleToGroups.map((group) => group.id)
    }

    try {
      const promise = updateWorkspace({ id: workspaceToEdit.id, data: payload }).unwrap()

      toast.promise(promise, {
        loading: 'Updating workspace...',
        success: 'Workspace updated successfully',
        error: (err) => {
          const errorMessage = err?.data?.error || 'Unable to update workspace'
          return errorMessage
        }
      })

      await promise
      setShowEditModal(false)
    } catch {}
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <div className={styles.WorkspaceTable}>
      <TableActions>
        <CreateWorkspace />
      </TableActions>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => {
            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

            return (
              <TableRow key={key} {...headerGroupProps}>
                <Fragment>
                  {headerGroup.headers.map((column) => {
                    const { key, ...headerProps } = column.getHeaderProps()

                    return (
                      <Th key={key} {...headerProps}>
                        {column.render('Header')}
                      </Th>
                    )
                  })}
                  <Th isOption />
                </Fragment>
              </TableRow>
            )
          })}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { key, ...rowProps } = row.getRowProps()
            const workspace = row.original

            return (
              <TableRow key={key} {...rowProps} clickable>
                {row.cells.map((cell) => {
                  const { key, ...cellProps } = cell.getCellProps()
                  return (
                    <Td key={key} {...cellProps} onClick={handleRowClick(workspace)}>
                      {cell.render('Cell')}
                    </Td>
                  )
                })}

                <Td>{rows.length > 0 && !workspace?.settings?.default && <WorkspaceOptions id={workspace.id} />}</Td>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {showEditModal && (
        <WorkspaceModal
          onHide={() => setShowEditModal(false)}
          data={workspaceToEdit}
          onSubmit={handleEditWorkspace}
          disableSubmitButton={updateWorkspaceResult.isLoading}
        />
      )}
    </div>
  )
}

export default WorkspaceTable
