// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RkgB9fMae7RXv8s6rIUu{display:flex;flex-direction:column;overflow-y:scroll;margin-bottom:10px;gap:15px}.A1N7gbCAyNYemfas8Zfr{display:flex;align-items:center;gap:10px;width:100%}.GI4M5Vu4xcPBGQGSE2iQ{display:flex;justify-content:center;align-items:center;font-size:20px;border-radius:50%;height:25px;width:25px;cursor:pointer}.Aj6uCFeLxGf8a3yHvmEw{background-color:var(--primary);color:var(--white, #fff)}.LsEmuMFnei45bMFXu5gZ{background-color:#c7c7c7;color:var(--white, #fff);padding-bottom:4px}.Hqmdf7ojkxABuy_fiuiS{display:flex;flex-direction:column;margin-bottom:0;width:80%}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Workspaces/WorkspaceModal/WorkspaceModal.module.css"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,kBAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,UAAA,CAGF,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CACA,iBAAA,CACA,WAAA,CACA,UAAA,CACA,cAAA,CAGF,sBACE,+BAAA,CACA,wBAAA,CAGF,sBACE,wBAAA,CACA,wBAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,eAAA,CACA,SAAA","sourcesContent":[".QueriesWrapper {\n  display: flex;\n  flex-direction: column;\n  overflow-y: scroll;\n  margin-bottom: 10px;\n  gap: 15px\n}\n\n.QueriesContainer {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  width: 100%\n}\n\n.QueryButton {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 20px;\n  border-radius: 50%;\n  height: 25px;\n  width: 25px;\n  cursor: pointer;\n}\n\n.QueryAddButton {\n  background-color: var(--primary);\n  color: var(--white, #fff);\n}\n\n.QueryRemoveButton {\n  background-color: hsl(0, 0%, 78.0%);\n  color: var(--white, #fff);\n  padding-bottom: 4px;\n}\n\n.InputFieldContainer {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 0;\n  width: 80%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"QueriesWrapper": "RkgB9fMae7RXv8s6rIUu",
	"QueriesContainer": "A1N7gbCAyNYemfas8Zfr",
	"QueryButton": "GI4M5Vu4xcPBGQGSE2iQ",
	"QueryAddButton": "Aj6uCFeLxGf8a3yHvmEw",
	"QueryRemoveButton": "LsEmuMFnei45bMFXu5gZ",
	"InputFieldContainer": "Hqmdf7ojkxABuy_fiuiS"
};
export default ___CSS_LOADER_EXPORT___;
