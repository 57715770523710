import { Button, FormControl, FormErrorText, Input, Label, Textarea } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'

import styles from './WorkspaceModal.module.css'

import ReactSelect from 'Components/ReactSelect'
import Sheet from 'Layout/Sheet'
import api from 'Services/api'
import { KNOWLEDGE_GROUP_VISIBILITY_STATES, WORKSPACE_VISIBILITY_OPTIONS } from 'Src/constants/user-groups'

const workspaceValidationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  visibility: yup.string().oneOf(Object.values(KNOWLEDGE_GROUP_VISIBILITY_STATES)).required(),
  visibleToGroups: yup.array().when('visibility', {
    is: KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC,
    then: (schema) => schema.min(1, 'Please select at least 1 User group').required()
  }),
  example_queries: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().trim().required('Query cannot be empty')
      })
    )
    .min(3, 'Minimum 3 queries required')
})

const WorkspaceModal = (props) => {
  const { data: account } = api.useGetAccountQuery()
  const accountType = account.account_type
  const { data = null, onHide, onSubmit, disableSubmitButton = false } = props

  const [getUserGroups, getUserGroupsResult] = api.useLazyGetUserGroupsQuery()

  const loadOptions = (inputValue, cb) => {
    const query = {
      search: inputValue,
      accountType
    }
    getUserGroups(query)
      .unwrap()
      .then((data) => {
        cb(data.results)
      })
  }

  const transformQueriesToFormData = (queries = []) => {
    const result = queries.map((query) => ({ value: query }))
    while (result.length < 3) {
      result.push({ value: '' })
    }
    return result
  }

  const {
    register,
    control,
    formState: { errors, isSubmitting },
    watch,
    handleSubmit
  } = useForm({
    defaultValues: {
      name: data?.name || '',
      description: data?.description || '',
      visibility: data?.visibility || KNOWLEDGE_GROUP_VISIBILITY_STATES.ALL,
      visibleToGroups: data?.user_groups || [],
      example_queries: transformQueriesToFormData(data?.example_queries)
    },
    resolver: yupResolver(workspaceValidationSchema)
  })

  const {
    fields: queryFields,
    append: appendQuery,
    remove: removeQuery
  } = useFieldArray({
    control,
    name: 'example_queries',
    rules: {
      minLength: 3
    }
  })

  const visibility = watch('visibility')
  const isSubmitButtonDisabled = disableSubmitButton || isSubmitting

  const handleRemove = (index) => {
    if (queryFields.length > 3) {
      removeQuery(index)
    }
  }

  const handleFormSubmit = (formData) => {
    const transformedData = {
      ...formData,
      example_queries: formData.example_queries.map((query) => query.value)
    }
    onSubmit(transformedData)
  }

  return (
    <Sheet title={data ? 'Edit Workspace' : 'Create Workspace'} onHide={onHide}>
      <form onSubmit={handleSubmit(handleFormSubmit)} style={{ display: 'contents' }}>
        <FormControl isInvalid={errors.name}>
          <Label>Name</Label>
          <Input {...register('name')} placeholder='' />
          {errors.name && <FormErrorText>{errors.name.message}</FormErrorText>}
        </FormControl>
        <FormControl isInvalid={errors.description}>
          <Label>Description</Label>
          <Textarea {...register('description')} placeholder='' />
          {errors.description && <FormErrorText>{errors.description.message}</FormErrorText>}
        </FormControl>
        <FormControl>
          <Label>Visibility</Label>
          <Controller
            name='visibility'
            control={control}
            render={({ field }) => (
              <ReactSelect
                {...field}
                value={WORKSPACE_VISIBILITY_OPTIONS.find((opt) => opt.value === field.value)}
                onChange={(opt) => field.onChange(opt.value)}
                isClearable={false}
                options={WORKSPACE_VISIBILITY_OPTIONS}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                placeholder='Select visibility'
              />
            )}
          />
          {errors.visibility && <FormErrorText>{errors.visibility.message}</FormErrorText>}
        </FormControl>

        {visibility === KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC && (
          <FormControl isInvalid={errors.visibleToGroups}>
            <Label>Select user group(s) to assign visibility</Label>
            <Controller
              name='visibleToGroups'
              control={control}
              shouldUnregister
              render={({ field }) => {
                return (
                  <ReactSelect
                    {...field}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder='Search user groups'
                    isClearable={false}
                    isMulti
                    isInvalid={Boolean(errors.users)}
                    options={[]}
                    loadOptions={loadOptions}
                    isLoading={getUserGroupsResult.isLoading}
                    loadingMessage={() => 'Searching for user groups...'}
                  />
                )
              }}
            />
            {errors.visibleToGroups && <FormErrorText>{errors.visibleToGroups.message}</FormErrorText>}
          </FormControl>
        )}
        <FormControl>
          <Label>Example Queries</Label>
          <div className={styles.QueriesWrapper}>
            {queryFields.map((field, index) => (
              <div key={field.id} className={styles.QueriesContainer}>
                <FormControl className={styles.InputFieldContainer} isInvalid={errors?.example_queries?.[index]?.value}>
                  <Input {...register(`example_queries.${index}.value`)} placeholder='Enter query' />
                  {errors?.example_queries?.[index]?.value && (
                    <FormErrorText>{errors.example_queries[index].value.message}</FormErrorText>
                  )}
                </FormControl>
                {index === queryFields.length - 1 && queryFields.length >= 3 && (
                  <div
                    className={`${styles.QueryButton} ${styles.QueryAddButton}`}
                    onClick={() => appendQuery({ value: '' })}
                  >
                    +
                  </div>
                )}
                {queryFields.length > 3 && (
                  <div
                    className={`${styles.QueryButton} ${styles.QueryRemoveButton}`}
                    onClick={() => handleRemove(index)}
                  >
                    -
                  </div>
                )}
              </div>
            ))}
          </div>
        </FormControl>
        <div style={{ marginTop: 'auto' }}>
          <Button variant='primary' type='submit' disabled={isSubmitButtonDisabled}>
            {data ? 'Save' : 'Submit'}
          </Button>
          <Button variant='link-muted' onClick={onHide}>
            Cancel
          </Button>
        </div>
      </form>
    </Sheet>
  )
}

export default WorkspaceModal
